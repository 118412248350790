import React from "react";
import { NavLink } from "react-router-dom";
import { removeUserSession } from '../utils/common';
const TopHeader = (props) => {
  const logout = () => {
    removeUserSession();
    window.location = '/login';
  }
  return (
    <div>
      <nav className="main-header navbar-fixed-top navbar navbar-expand-md navbar-light navbar-white">

        <div class="container-fluid">
          <NavLink exact to="/dashboard" class="navbar-brand">
            <img src="/dist/img/neoindus.png" height="30" alt="NeoIndus Logo"></img> &nbsp;
            <span class="brand-text font-weight-bold">NEOINDUS</span>
          </NavLink>
          <button
            class="navbar-toggler order-1"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse order-3" id="navbarCollapse">
            <ul class="navbar-nav">
              <li class="nav-item">
                <NavLink exact to="/indus" className="nav-link">
                  Dashboard
                </NavLink>
              </li>
              <li class="nav-item dropdown">
                <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle">Manage Farms</a>
                <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu border-0 shadow">
                  <NavLink exact to="/farms" className="nav-link">
                    Batch History
                  </NavLink>
                  
                  {/* <NavLink exact to="/" className="nav-link">
                    Farmers
                  </NavLink> */}
                </ul>
              </li>
              {/* <li class="nav-item">
                <NavLink exact to="/indus" className="nav-link">
                  Dashboard
                </NavLink>
              </li> */}
              <li class="nav-item">
                <NavLink exact to="/dashboard" className="nav-link">
                  Farm Conditions
                </NavLink>
              </li>
              <li class="nav-item dropdown">
                <NavLink exact to="/batchOperations" className="nav-link">
                  Readings
                </NavLink>
              </li>
              <li class="nav-item dropdown">
                <NavLink exact to="/users" className="nav-link">
                  App Users
                </NavLink>
              </li>
              <li class="nav-item dropdown">
                <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle">Device Management</a>
                <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu border-0 shadow">
                  <NavLink exact to="/deviceConnections" className="nav-link">
                    Connections
                  </NavLink>
                  <NavLink exact to="/deviceMaster" className="nav-link">
                    Device Master
                  </NavLink>
                  <NavLink exact to="/abnormalReadings" className="nav-link">
                    Abnormal Readings
                  </NavLink>
                  <NavLink exact to="/thresholdSettings" className="nav-link">
                    Threshold Settings
                  </NavLink>
                </ul>
              </li>

            </ul>
          </div>
          <div class="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
            <button type="button" class="btn btn-secondary btn-sm" style={{ fontSize: 12 }} onClick={() => { logout() }}>LOGOUT</button>
          </div>
          {/* <ul class="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
            
            <li class="nav-item dropdown">
              <a class="nav-link" data-toggle="dropdown" href="#">
                <i class="far fa-bell"></i>
                <span class="badge badge-warning navbar-badge">15</span>
              </a>
              <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <span class="dropdown-header">15 Notifications</span>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item">
                  <i class="fas fa-envelope mr-2"></i> 4 new messages
                  <span class="float-right text-muted text-sm">3 mins</span>
                </a>
                <div class="dropdown-divider"></div>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item dropdown-footer">
                  See All Notifications
                </a>
              </div>
            </li>
          </ul> */}
        </div>

      </nav>
    </div>
  );
};
export default TopHeader;
