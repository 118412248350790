import axios from "axios";
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { env } from './const';
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
import { headers } from '../utils/common';
import moment from "moment/moment";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DataTable from "react-data-table-component";
import { Modal, Button } from 'react-bootstrap';
import AbnormalReadingsModal from './AbnormalReadingsModal';
import { AiOutlineReload } from "react-icons/ai";
const Comissioned = (props) => {
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="30" color="#333" />,
    });
    const [headersobj] = useState(headers());
    const [showLoader, setShowloader] = useState(false);

    const { register, handleSubmit, watch, formState: { errors },
        setValue,
        trigger,
    } = useForm({
        mode: 'onChange'
    });

    const [connectionData, setConnectionData] = useState([]);
    const columns = [
        {
            name: '#',
            selector: row => row.index,
            sortable: true,
            width: '70px',
            cell: (row, index) => index + 1 // Add 1 because index starts from 0
        },
        {
            name: "IMEI Number",
            selector: "imeiNumber",
            width: '200px',
            sortable: true,
            cell: row => <div style={{ cursor: 'pointer', color: 'blue' }} onClick={() => clickHandler(row)} >{row.imeiNumber}</div>,
        },
        {
            name: "Device Name",
            selector: "deviceName",
            width: '250px',
            sortable: true,
            cell: row => <div>{row.deviceName}</div>,
        },
        {
            name: "Signal Strength",
            selector: "signalStrength",

            sortable: true,
            cell: row => <div className={row.colorCode}><b>{row.signalStrength}</b></div>,
        },
        {
            name: "Status",
            selector: "connectionStatus",
            width: '120px',
            sortable: true,
            cell: row => <div className={row.colorCode}>{row.connectionStatus}</div>,
        },
        {
            name: "Abnormalities(today)",
            selector: "abnormalDetectedToday",
            width: '200px',
            sortable: true,
            cell: row => (
                <div 
                    className={row.abnormalDetectedToday === 'Yes' ? "text-danger" : "text-success"} 
                    style={{ cursor: row.abnormalDetectedToday === 'Yes' ? 'pointer' : 'default' }}
                    onClick={() => row.abnormalDetectedToday === 'Yes' ? abnormalClickHandler(row) : null}
                >
                    {row.abnormalDetectedToday}
                </div>
            ),
        },
        {
            name: "Last updated at",
            selector: "lastUpdatedDate",
            width: '200px',
            sortable: true,
            cell: row => <div>{row.lastUpdatedDate}</div>,
        },
      
        {
            name: "Installed Location",
            selector: "currentLocation",
            width: '200px',
            sortable: true,
            cell: row => <div>{row.currentLocation}</div>,
        },
    ];
    const [originalData, setOriginalData] = useState([]); // State to keep original data
    const [key, setKey] = useState(0);
    const connections = () => {

        setShowloader(true);

        axios.get(env.produrl + '/admin/deviceStatus/'+props.action, { headers: headersobj })
            .then(res => {
                if (!res.data.status) {
                    alert(res.data.message);
                    return;
                }
                setProgress(false);
                setConnectionData(res.data.list);
                setOriginalData(res.data.list)
                console.log(res.data.list)
                setShowloader(false);
                

            }).catch((err) => {
                alert(err.message);
                console.log(err);

                setShowloader(false);
            });
    };
    useEffect(() => {
        connections();
    }, []);
    const [searchTerm, setSearchTerm] = useState('');
    const [progress, setProgress] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const handleChangeSelectedRows = (state) => {
        //alert(JSON.stringify(state.selectedRows))
        setSelectedRows(state.selectedRows)
    }
    useEffect(() => {

        if (searchTerm !== '' && searchTerm.length > 2) {
            const filteredData = connectionData.filter(item => {
                const deviceName = item.deviceName || "";
                const assignedTo = item.assignedTo || "";
                const currentLocation = item.startDateTime || "";
                const imei = item.imeiNumber || "";
                const connectionStatus = item.connectionStatus || "";
                return deviceName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    assignedTo.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    imei.includes(searchTerm) ||
                    currentLocation.includes(searchTerm) || connectionStatus.toLowerCase().includes(searchTerm.toLowerCase())
            });
            setConnectionData(filteredData);
        } else {
            setConnectionData(originalData);
        }
    }, [searchTerm]);
    const [showPopup, setShowPopup] = useState(false);
    const closePopup = () => {
        setShowPopup(false);
    }
    const [rowData, setRowData] = useState(null);
    const clickHandler = async (row) => {
        console.log(row)
        setShowPopup(true);
        setRowData(row)
    };
    const [showResults, setShowResults] = useState(false)
    const [resultMessage, setResultMessage] = useState({});
    const onSubmit = (data) => {
        let _data = {
            batchID: rowData?.batchID,
            location: rowData?.currentLocation,
            operationType: data.status,
            farmID: rowData?.farmID,
            imeiNumber: rowData?.imeiNumber,
            remarks: rowData?.remarks
        }
        console.log(_data)
        axios.post(env.produrl + `/batchops/action/`, _data, { headers: headersobj })
            .then(res => {
                if(res.status === 200) {  
                    setShowResults(true);
                    props.onUpdate();
                    connections();
                    setResultMessage({
                        error: false, 
                        message: res.data.message 
                    });
                } else { 
                    setResultMessage({
                        error: true, 
                        message: 'Unexpected status returned: ' + res.status
                    });
                }
            })
            .catch((err) => {
                alert(err.message);
                console.log(err);
                setResultMessage({
                    error: true, 
                    message: (err.response && err.response.data && err.response.data.message) ? err.response.data.message : 'An error occurred'
                });
            });
    }
    const [showAbnormalModal, setShowAbnormalModal] = useState(false);
    const closeAbnormalModal = () => {
        setShowAbnormalModal(false);
    };

    const abnormalClickHandler = (row) => {
        setShowAbnormalModal(true);
        setRowData(row);
    };
    return (
        <div className="dashboard">
            <div className="row" style={{ marginBottom: 20 }}>
                <div className="col-md-6">
                    <form>
                        <div class="input-group">
                            <input type="search" className="form-control form-control-sm"
                                placeholder="Search By IMEI/Device Name/Location/Connection Status"
                                //value={searchTerm}
                                onKeyUp={(e) => setSearchTerm(e.target.value)}
                            />
                            <div class="input-group-append">
                                <div type="submit" className="btn btn-sm btn-default">
                                    <i class="fa fa-search"></i>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-md-6" style={{ textAlign: "right" }}>
                    <span className="refresh" onClick={() => { connections() }}><AiOutlineReload style={{ marginTop: -5 }} /> Refresh</span>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <DataTable
                        progressPending={progress}
                        columns={columns}
                        data={connectionData}
                        defaultSortAsc={false}
                        pagination
                        dense='false'
                        compact
                        highlightOnHover='true'
                        striped
                        paginationPerPage={25}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                        onRowSelected={handleChangeSelectedRows}
                    />
                </div>
            </div>
            <AbnormalReadingsModal show={showAbnormalModal} handleClose={closeAbnormalModal} rowData={rowData} />
            <Modal show={showPopup} size='lg'>
                <Modal.Header>
                    <Modal.Title>Update Status - <strong>IMEI Number: </strong>{rowData?.imeiNumber}, <strong>Device Name: </strong>{rowData?.deviceName}</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor>Selet Status</label>
                                            <select class="form-control" name="status" id="status"
                                                {...register("status", {
                                                    required: "Please select status",

                                                })}
                                            >
                                                <option value="">-Select-</option>
                                              
                                                <option value="Hibernation">Hibernate</option>
                                                <option value="Decommisioned">Decomission</option>

                                            </select>
                                            {errors.status && <span className="err-msg">{errors.status.message}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor>Remarks</label>

                                            <textarea class="form-control" name="remarks" id="remarks" cols={2}
                                                {...register("remarks", {
                                                    required: "Please enter remarks",
                                                })}
                                            ></textarea>
                                            {errors.remarks && <span className="err-msg">{errors.remarks.message}</span>}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        { showResults ? <Results key={Math.random()} message={resultMessage.message} error = {resultMessage.error}  /> : null }
                    </Modal.Body>
                    <Modal.Footer>
                        <input type="submit" value="Submit" class="btn btn-primary newBtn" />
                        <Button variant="secondary" onClick={closePopup}>Close</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
};
const Results = (props) => {
    let error, classes;
    if (props.error === false) {
        error = 'Success';
        classes = 'alert alert-success alert-dismissible fade show'
    } else {
        error = 'Error!';
        classes = 'alert alert-danger alert-dismissible fade show'
    }
    return (
        <div className="results">
            <div className={classes}>
                <strong>{error}</strong> {props.message}
                <button type="button" class="close" data-dismiss="alert">&times;</button>
            </div>
        </div>
    );
}
export default Comissioned;
