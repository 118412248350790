import axios from "axios";
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { env } from './const';
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
import { headers } from '../utils/common';
import moment from "moment/moment";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DataTable from "react-data-table-component";
import { Modal, Button } from 'react-bootstrap';

import { AiOutlineReload } from "react-icons/ai";
const Farms = (props) => {
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="30" color="#333" />,
    });
    const [headersobj] = useState(headers());
    const [showLoader, setShowloader] = useState(false);

    const { register, handleSubmit, watch, formState: { errors },
        setValue,
        trigger,
    } = useForm({
        mode: 'onChange'
    });

    const [connectionData, setConnectionData] = useState([]);
    const columns = [
        {
            name: '#',
            selector: row => row.sno,
            sortable: true,
            width: '70px',
            // cell: (row, index) => index + 1 // Add 1 because index starts from 0
        },
        {
            name: "Farm Name",
            selector: "farmName",
            width: '200px',
            sortable: true,
            cell: row => <div style={{ cursor: 'pointer', color: 'blue' }} onClick={() => clickHandler(row)} >{row.farmName}</div>,
        },
        {
            name: "Farmer Name",
            selector: "name",
            width: '250px',
            sortable: true,
            cell: row => <div>{row.name}</div>,
        },
        {
            name: "created Date",
            selector: "createdDate",
            width: '150px',
            sortable: true,
            cell: row => <div>{row.farmCreatedDate ? moment(row.farmCreatedDate).format('DD-MMM-YYYY') : ""}</div>,
        },
        {
            name: "Batch Name",
            selector: "batchName",
            width: '200px',
            sortable: true,
            cell: row => <div>{row.batchName}</div>,
        },
        {
            name: "Batch Start Date",
            selector: "",
            width: '200px',
            sortable: true,
            cell: row => <div>{row.batchCreatedDate ? moment(row.batchCreatedDate).format('DD-MMM-YYYY') : ""}</div>,
        },
        {
            name: "Batch Status",
            selector: "batchStatus",
            width: '200px',
            sortable: true,
            cell: row => <div>{row.batchStatus}</div>,
        },

    ];
    const [originalData, setOriginalData] = useState([]); // State to keep original data
    const [farms, setFarms] = useState([]);
    const [key, setKey] = useState(0);

    const getALlFarms = () => {

        setShowloader(true);

        axios.get(env.produrl + '/misc/listoffarms_all/', { headers: headersobj })
            .then(res => {
                if (!res.data.status) {
                    alert(res.data.message);
                    return;
                }
                setProgress(false);
                setFarms(res.data.data);
                setOriginalData(res.data.data)
                console.log(res.data.list)
                setShowloader(false);


            }).catch((err) => {
                alert(err.message);
                console.log(err);

                setShowloader(false);
            });
    };
    useEffect(() => {
        getALlFarms();
    }, []);

    const [searchTerm, setSearchTerm] = useState('');
    const [progress, setProgress] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const handleChangeSelectedRows = (state) => {
        //alert(JSON.stringify(state.selectedRows))
        setSelectedRows(state.selectedRows)
    }
    const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
        <div className="custom-control custom-checkbox">
            <input
                type="checkbox"
                className="custom-control-input"
                ref={ref}

                {...rest}
            />
            <label className="custom-control-label" onClick={onClick} />
        </div>
    ));
    useEffect(() => {

        if (searchTerm !== '' && searchTerm.length > 2) {
            const filteredData = farms.filter(item => {
                const farmname = item.farmName || "";
                const farmerName = item.name || "";
                const batchName = item.batchName || "";
                return farmname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    farmerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    batchName.toLowerCase().includes(searchTerm.toLowerCase())
            });
            setFarms(filteredData);
        } else {
            setFarms(originalData);
        }
    }, [searchTerm]);
    const [showPopup, setShowPopup] = useState(false);
    const closePopup = () => {
        setShowPopup(false);
    }
    const [rowData, setRowData] = useState(null);
    const clickHandler = async (row) => {
        console.log(row)
        setShowPopup(true);
        setRowData(row)
    };
    const [showResults, setShowResults] = useState(false)
    const [resultMessage, setResultMessage] = useState({});
    const handleChange = (state) => {
        setSelectedRows(state.selectedRows);

        if (state.selectedRows.length > 0) {
            console.log(state.selectedRows[0]);
        }
    }
    return (
        <div className="dashboard">
            <div className="row">
                <div className="col-md-6">
                    <ul className="headerTitle">
                        <li><h3>List of Farms</h3></li>
                        <li>
                            {showLoader ?
                                <section {...containerProps} style={{ "margin-top": "0px" }}>
                                    {indicatorEl}
                                </section> : ""
                            }
                        </li>
                    </ul>
                </div>
                <div className="col-md-6" style={{ textAlign: "right" }}>
                    <span className="refresh" onClick={() => { getALlFarms() }}><AiOutlineReload style={{ marginTop: -5 }} /> Refresh</span>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row" style={{ marginBottom: 20 }}>
                        <div className="col-md-6">
                            <form>
                                <div class="input-group">
                                    <input type="search" className="form-control form-control-sm"
                                        placeholder="Search By IMEI/Device Name/Location/Connection Status"
                                        //value={searchTerm}
                                        onKeyUp={(e) => setSearchTerm(e.target.value)}
                                    />
                                    <div class="input-group-append">
                                        <div type="submit" className="btn btn-sm btn-default">
                                            <i class="fa fa-search"></i>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6">
                            <ul className="controls">
                                <li><button class="btn btn-block btn-outline-danger btn-sm closebatch_btn">Close Batch</button></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <DataTable
                                progressPending={progress}
                                columns={columns}
                                data={farms}
                                defaultSortAsc={false}
                                pagination
                                dense='false'
                                compact
                                highlightOnHover='true'
                                striped
                                paginationPerPage={25}
                                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                //onRowSelected={handleChangeSelectedRows}
                                selectableRows
                                selectableRowsComponent={BootyCheckbox}
                                onSelectedRowsChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Farms;
