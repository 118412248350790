import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { env } from './const';
import { setUserSession } from '../utils/common';

const Login = (props) => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'onChange'
    });
    
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    
    const onSubmit = data => {
        axios.post(`${env.produrl}/login`, data)
            .then(res => {
                if (res.status === 200 && res.data.userDetails[0].userType === 'Admin') {
                    setUserSession(res.data.token, res.data.userDetails[0], res.data.browserDetails);
                    props.history.push('/indus');
                } else {
                    alert('Invalid Login');
                }
            }).catch(err => {
                setShowErrorMessage(true);
                setErrorMessage(err.response?.data.message || 'An error occurred');
            });
    };

    return (
        <div className="login-page">
            <div className="login-box">
                <div className="login-logo">
                    <img src='dist/img/logo-big.png' width='50%' alt="Poultry Pal" />
                </div>
                <div className="card">
                    <div className="card-body login-card-body">
                        <p className="login-box-msg">Sign in to start your session</p>
                        {showErrorMessage && <center><span className="err-msg">{errorMessage}</span></center>}
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="User ID"
                                    {...register('username', {
                                        required: 'Please enter User ID'
                                    })}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fa fa-user-circle" />
                                    </div>
                                </div>
                            </div>
                            {errors.username && <span className="err-msg">{errors.username.message}</span>}

                            <div className="input-group mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Password"
                                    {...register('password', {
                                        required: 'Please enter Password'
                                    })}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock" />
                                    </div>
                                </div>
                            </div>
                            {errors.password && <span className="err-msg">{errors.password.message}</span>}

                            <div className="row">
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary btn-block">Log In</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
