import React from "react";

const TopFooter = (props) => {
  return (
    <div>
      <footer class="main-footer">
          Copyright &copy; 2023-2024 <a href="#">NEOAURA</a>. All rights reserved.
      </footer>
    </div>
  );
};
export default TopFooter;
